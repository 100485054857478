<template>
    <div class="payPage">
        <van-nav-bar
                title='支付界面'
                left-arrow
                fixed
                class="qjc-nav-bar"
                @click-left="back()"
        >
			<img @click="$router.push('/aizh')" class="goHome" src="../../assets/images/home.png" slot="right" alt="">
		</van-nav-bar>
        <div class="payBox">
            <div class="package">
                您購買的是：{{ packageName }}<span>價格：{{Number(price).toFixed(2)}}港元</span>
            </div>
            <div class="group">
                <span class="groupTit">如果您有禮品碼或優惠碼，請在這裏輸入:</span>
				<div class="disc_cell">
					<van-field v-model="discountCode" class="dis_input" :disabled="disNot" @change="onChange" @input="onInput" @paste="onPaste" placeholder="請輸入您的禮品碼或優惠碼" />
					<van-button class="disc_btn" :disabled="disNot" @click="verifyCodeType">確定</van-button>
				</div>
				<!-- onkeyup="this.value=this.value.replace(/[^\w]/g,'')" -->
				<div class="discing" v-if="isVing" >驗證中...</div>
			</div>
			<div class="goods_wrap">
				<div class="goods_cell">
					<div class="goods_cell_label">套餐總價</div>
					<div class="goods_cell_price">HK <span>0.00</span></div>
					<!-- {{Number(price).toFixed(2)}} -->
				</div>
				<div class="goods_cell">
					<div class="goods_cell_label">優惠金額</div>
					<div class="goods_cell_price">HK <span>0.00</span></div>
					<!-- {{(price - payPrice).toFixed(2)}} -->
				</div>
				<div class="goods_cell">
					<div class="goods_cell_label">需支付</div>
					<div class="goods_cell_price pay_price">HK <span>0.00</span></div>
					<!-- {{Number(payPrice).toFixed(2)}} -->
				</div>
				
			</div>
            <!-- <div class="group">
                <span class="groupTit">您希望如何付款？</span>
                <div v-if="notGift" class="payList" :class="{'selPay': paymentMethod == payItem.id,'wx-tip-bottom qjc-relative': paymentMethod == 1,'paypal': payItem.id == 5}" v-for="(payItem,index) in payList" :style="{ 'background-image': 'url(' + payItem.imgUrl + ')','background-repeat':'no-repeat'}" @click="selPay(payItem.id)">
					<p v-if="payItem.id == 1" v-show="paymentMethod == 1" class="wx-tip qjc-absolute qjc-c-primary qjc-fts-24">如果您希望使用微信支付，請使用微信掃碼打開本平台</p>
				</div>
			</div>
            <div class="group" v-if="paymentMethod == 100">
                <span class="groupTit">請輸入您的卡片信息：</span>
                <input type="text" v-model="cardID" placeholder="信用卡卡号">
                <input type="text" v-model="expirationDate" placeholder="有效期：月/年" @focus="pickerShow = true">
                <input type="text" v-model="securityCode" style="float:left;width: 2.6rem;" placeholder="安全碼">
                <div class="security">
                    <van-icon name="question" color="#B8C0CC" size="20" @click="!securityTip"/>
                    <div class="cardTip" v-if="securityTip">
                        <div class="cardBox">
                            <img src="../../assets/images/card.png" alt="">
                            <span>如何找到安全碼</span>
                            <p>
                                Visa、Mastercard卡的三位安全码
                                位於卡片背面的簽名條右側。
                            </p>
                        </div>
                        <div class="triangle"></div>
                    </div>
                </div>
            </div> -->


            <van-button @click="toPay" :loading="loading" :disabled="loading" class="qjc-wid-100 qjc-ftw-b qjc-bg-primary qjc-fts-32 qjc-c-fff">立即支付</van-button>

           <!-- <div class="way qjc-texta-l">
				<p>完成體檢套餐的購買後，請在30天內完成信息填寫與體檢預約，逾期將導致套餐失效並無法退還已支付的款項。</p>
                
            </div> -->
        </div>
        <van-popup v-model="pickerShow" position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                :formatter="formatter"
                @cancel="pickerShow = false"
                @confirm="pickerConfirm"
        /></van-popup>

		<!-- 支付失败弹窗 -->
		<van-popup class="err-box" v-model="payErrBox"  :close-on-click-overlay='false'>
			<img src="../../assets/images/zhifushibai.png" alt="">
			<p class="qjc-fts-36">支付失敗</p>
			<div>
				<van-button
					round
					@click="payAgain"
					class="qjc-borderc-primary qjc-c-primary qjc-fts-36"
				>
					重新支付
				</van-button>
			</div>
			<router-link class="qjc-fts-28" to="/">返回首頁</router-link>
			<span @click="payErrBox = false" class="err-close qjc-c-fff qjc-absolute">&times;</span>
		</van-popup>

    </div>
</template>

<script>
    import Vue from 'vue'
    import {NavBar, Field, Button, Toast, Icon, DatetimePicker, Popup} from 'vant'

    Vue.use(NavBar).use(Field).use(Button).use(Toast).use(Icon).use(DatetimePicker).use(Popup);
	
	import { pay, isPay, wxPayHK, paypal, getGoodsList, codeType, giftCodePay, getWxStatus } from "@/js/axios.js"

    export default {
        name: "payment",
        data() {
            return {
                id:0,
                package: 0,
				packageName: '',
                price: 0,
				payPrice: 0,
                cardID: '',
                offerCode: '',
                expirationDate: '',
                securityCode: '',
                securityTip: false,
                paymentMethod:'',
                pickerShow:false,
                payList:[
                    {id:1,imgUrl:require("../../assets/images/weixinzhifu.png")},
                    // {id:2,imgUrl:require("../../assets/images/zhifubao.png")},
                    // {id:3,imgUrl:require("../../assets/images/yinlianzhifu.png")},
                    // {id:4,imgUrl:require("../../assets/images/bocpay.png")},
                    {id:5,imgUrl:require("../../assets/images/xinyongka.png")},
                ],
                newDate: new Date().getTime(),
                currentDate: new Date(),
				itemName: '',//订单名称
				itemNumber: '',//订单编号
				amount: '',//价格
				token: localStorage.getItem('access_token'),
				payErrBox: false,//支付失败弹窗
				
				discountCode: '',// 优惠码
				
				loading: false,
				
				notGift: true,// 非礼品码(礼品码时隐藏支付方式),
				giftType: '',
				orderid: '',
				disNot: false,
				isVing: false,
            }
        },
		computed: {
			formMethod (){
				let url = location.href;
				if(/mtest/.test(url)){
					return 'http://test.medinbd.com';
				}else{
					return 'https://www.medinbd.com';
				}
			}
		},
        created() {
			// 是否扫礼品码进入
			this.package = sessionStorage.getItem('package');
			
			// 是否本地存储有优惠码
			// var disCode = sessionStorage.getItem('discountCode');
			// if(disCode){
			// 	this.discountCode = disCode;
			// }
			
			if(this.package == null){
				Toast.fail('請先選擇套餐');
				this.$router.push('/aizh');
			}else{
				this.getPackageInfo();
			}
        },
        mounted() {
				this.orderid = sessionStorage.getItem('orderid') ? sessionStorage.getItem('orderid') : ''
				sessionStorage.removeItem('discountCode');
			  
				this.id = this.$route.params.id
			  
				if(this.id == 1){
					Toast.success("支付成功");
					let data = {
						o_sn: sessionStorage.getItem('o_sn') 
					}
					getWxStatus(data).then((res) => {
						if(res.code == 200) {
							// 清除优惠码
							sessionStorage.removeItem('discountCode');
							sessionStorage.removeItem('orderid')
							sessionStorage.removeItem('o_sn')
							sessionStorage.removeItem('package')
							this.$router.replace({path:'/aizh/result/'+ res.data.id}).catch( err => {
										 
							})		
							
						}
					})
				}else if(this.id == 2) {
					this.payErrBox = true;
				} 
        },
        methods: {
			// navbar返回
			back() {
				this.$router.back(-1)
				// if(this.package != 4){
				// 	this.$router.push('/home/buyCombo');
				// }else{// 疫境求真
				// 	this.$router.push('/home/buyYjqz');
				// }
			},
			format( data ) {
			    return data.replace( /[^\w\s/]/ig, '' );
			},
			// .replace( /\s/g, '' ).replace( /....(?!$)/g, '$& ' )
			onInput() {
			    let separateChars = this.format( this.discountCode );
			    this.discountCode = separateChars;
			},
			onPaste(e) {
			    setTimeout( () => {
			       this.discountCode = this.format( e.target.value.replace( /\n|\r|\t|\v| /g, '' ) );
			       this.onChange();
			    }, 100 );
			},
			onChange(){
				console.log(this.discountCode)
			},
			// 优惠码类型检测
			verifyCodeType() {
				if(this.discountCode){
					this.isVing = true
					codeType({
						qid: this.orderid,
						code: this.discountCode,
					}).then(res => {
						// console.log(res)
						if(res.code == 200){
							let resT = JSON.parse(window.atob(res.data));
							this.giftType = resT.type;
							this.payPrice = resT.amount
							this.disNot = true
							this.isVing = false
							Toast.success('優惠碼驗證成功');
							if(resT.amount == 0) {
								this.notGift = false;
								this.package = sessionStorage.getItem('package');
							} else {
								this.notGift = true;
							}
						}else{
							this.notGift = true;
							this.payPrice = this.price;
							this.package = sessionStorage.getItem('package');// 切换为疫境求真
							// this.getPackageInfo();
							Toast.fail(res.msg);
							
							this.disNot = false
							this.isVing = false
						}
					});
				}else{
					this.notGift = true;
					this.disNot = false
					this.isVing = false
				}
			},
			getPackageInfo (){//获取当前套餐信息
				getGoodsList().then(res => {
					if(res.code == 200){
						var result = JSON.parse(window.atob(res.data));
						
						var combo = result.filter( a => a.id == this.package );
						
						if(combo.length>0){
							this.packageName = combo[0].name;
							this.price = Number(combo[0].price);
							this.payPrice = Number(combo[0].price);
						}else{
							this.$router.back(-1);
						}
					}else{
						this.$router.back(-1);
					}
				});
			},
            selPay(id){
                this.paymentMethod = id;
            },
            pickerConfirm(){
                let dateFormat = (new Date(this.currentDate)).getTime();
                this.expirationDate = new Date(dateFormat).toLocaleString().replace(/:\d{1,2}$/, ' ');
                this.pickerShow = false
            },
            formatter(type, value) {
                if (type === 'year') {
                    return `${value}年`;
                } else if (type === 'month') {
                    return `${value}月`
                }
                return value;
            },
            toPay(){
				this.loading = true;
				this.notGift = false;
				
				if(!this.notGift){
					wxPayHK({
						qid: this.orderid,
						disc: this.disNot ? this.discountCode : '',
						te: 1,
					}).then(res => {
						this.loading = false;
						if(res.code == 200){
							var result = JSON.parse(atob(res.data));
							if(result.id && result.id > 0) {
								sessionStorage.removeItem('orderid')
								sessionStorage.removeItem('package')
								sessionStorage.removeItem('discountCode')
								this.$router.replace('/aizh/result/' + result.id)
							} else {
								sessionStorage.removeItem('package')
								sessionStorage.removeItem('discountCode')
								this.$router.replace('/aizh/user')
							}
							
						}else{
							Toast.fail(res.msg);
						}
					});
				}else if(this.paymentMethod == 1){
					wxPayHK({
						disc: this.disNot ? this.discountCode : '',
						qid: this.orderid,
						te: 1,
					}).then(res => {
						this.loading = false;
						
						if(res.code == 200){
							var result = JSON.parse(atob(res.data));
							// console.log(res, result)
							
							// 存储优惠码至sessionStorage
							sessionStorage.setItem('discountCode', this.disNot ? this.discountCode : '')
							
							if(result.ntp == 0) {
								if(result.id) {
									sessionStorage.removeItem('orderid')
									sessionStorage.removeItem('package')
									this.$router.replace('/aizh/result/' + result.id)
								} else {
									sessionStorage.removeItem('package')
									sessionStorage.removeItem('discountCode')
									this.$router.replace('/aizh/user')
								}
							} else {
								location.href = result.url;
							}
							
						}else{
							Toast.fail(res.msg);
						}
					})
				}else if(this.paymentMethod == 5){
                    paypal({
						qid: this.orderid,
						te: 1,
						cancelUrl: location.href,
						disc: this.disNot ? this.discountCode : ''
					}).then(res => {
						this.loading = false;
						
						if(res.code == 200){
							var result = JSON.parse(atob(res.data))
							// console.log(result)
							location.href = result.url;
						}else{
							Toast.fail(res.msg);
						}
					});
                }else {
					this.loading = false;
					Toast.fail("請選擇支付方式")
                }
            },
			payAgain (){
				this.payErrBox = false;
				this.$router.push('/payment/0');
			}//支付失敗 重新支付
        },
        watch:{
            $route (){
                this.id = this.$route.params.id;
            },// 路由变化更新id
     //        id (newVal){
     //            if(newVal == 1){
     //                Toast.success("支付成功");
					
					// // 清除优惠码
					// sessionStorage.removeItem('discountCode');
					// sessionStorage.removeItem('orderid')
					// sessionStorage.removeItem('package')
					
     //                this.$router.push({path:'/user'}).catch( err => {

     //                })
     //            }else if(newVal == 2) {
     //                this.payErrBox = true;
     //            }
     //        }// id变化,重新获取数据
        },
    }
</script>

<style  scoped>
    .payPage {

    }
    .payPage >>> .van-cell{

    }
    .payBox {
        margin: 0.88rem 0.25rem;
        overflow: hidden;
    }

    .package {
        padding: 0.5rem 0 0.24rem 0;
        border-bottom: 0.01rem solid #DCDEE5;
        font-size:0.28rem;
        text-align: left;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(49,51,57,1);
    }

    .package span {
        float: right;
        color: #6681FA;
        font-size:0.24rem;
        font-family:PingFang SC;
        font-weight:500;
    }
    .group{
        margin-top: 0.64rem;
        text-align: left;
    }
    .groupTit{
        font-size:0.32rem;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(49,51,57,1);
    }
    .group input{
        width: 100%;
        height: 0.88rem;
        margin-top: 0.24rem;
        /* background:rgba(255,255,255,1); */
        border:0.01rem solid rgba(213,216,222,1);
        border-radius:0.08rem;
        text-indent: 0.25rem;
    }
    .payList{
        width: 100%;
        height: 1.22rem;
        margin-top: 0.24rem;
        border:0.01rem solid rgba(213,216,222,1);
        background-size: 2.4rem 0.6rem;
        background-position: 0.24rem center;
        border-radius:0.08rem;
        text-indent: 0.25rem;
    }
    .selPay{
        border:0.02rem solid #6681FA;
    }
    .security{
        position: relative;
        float: left;
        margin: 0.5rem 0.16rem 0 0.16rem;
    }
    .way{
        margin-top: 0.56rem;
        padding-top: 0.48rem;
        border-top: 0.01rem solid #DCDEE5;
		color: #777F8F;
    }
    .way ul {
        color: #777F8F;
        line-height: 0.44rem;
    }
    .way li {
        position: relative;
        padding-left: 0.2rem;
    }
    .way li::before{
         content: '';
         position: absolute;
         left: 0;
         top: 0.5em;
         width: 0.08rem;
         height: 0.08rem;
         background-color: #777F8F;
         border-radius: 50%;
    }
    .qjc-wid-100{
        margin-top: 0.77rem;
    }
    .cardBox{
        width:4.9rem;
        height:3.33rem;
        background:rgba(250,250,252,1);
        border-radius:0.16rem;
        overflow: hidden;
    }
    .cardBox img{
        display: block;
        margin: 0.32rem;
        width:1.76rem;
        height:1.2rem;
    }
    .cardBox span{
        margin-top: 0.32rem;
        font-size:0.28rem;
        font-family:PingFang SC;
        font-weight:500;
        color:rgba(119,127,143,1);
        margin-left: 0.32rem;
    }
    .cardBox p{
        font-size:0.28rem;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(119,127,143,1);
        margin-left: 0.32rem;
    }
    .cardTip{
        position: absolute;
        top: -3.53rem;
        left: -2.25rem;
    }
    .triangle{
        margin: 0 auto;
        width: 0;
        height: 0;
        border: 0.2rem solid;
        border-color: #FAFAFC transparent transparent;
    }

	.err-box{
		width: 5.5rem;
		padding: 0.72rem 0 0.44rem;
		border-radius: 0.16rem;
		overflow-y: visible;
	}
	.err-box img{
		width: 2.28rem;
		height: 2.5rem;
	}
	.err-box p{
		font-weight: 400;
		margin: 0.48rem 0;
	}
	.err-box .van-button{
		width: 2.86rem;
		height: 0.88rem;
		line-height: 0.88rem;
		margin-bottom: 0.15rem;
	}
	.err-box .qjc-fts-28{
		color: #777F8F;
	}
	.err-close{
		font-size: 0.42rem;
		width: 0.72rem;
		height: 0.72rem;
		line-height: 0.72rem;
		border: 0.02rem solid #fff;
		border-radius: 50%;
		left: 50%;
		bottom: -1.3rem;
		transform: translateX(-50%);
	}
	
	.goHome{
		width: 0.32rem;
		height: 0.32rem;
		vertical-align: middle;
	}
	
	.wx-tip{
		top: 100%;
		left: 0;
		margin-top: 0.12rem;
		text-indent: 0;
	}
	.wx-tip-bottom{
		margin-bottom: 0.6rem;
	}
	.paypal{
		background-size: auto 0.58rem;
		background-position: 0.24rem 0.32rem;
	}
	.goods_wrap {
		width: 100%;
		padding: 0.3rem 0.24rem 0;
		border: 0.01rem solid #6681FA;
		margin-top: 0.4rem;
		border-radius: 0.08rem;
	}
	.goods_cell {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.24rem;
	}
	.goods_cell_label {
		font-size: 0.28rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 0.40rem;
	}
	.goods_cell_price {
		font-size: 0.34rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #333333;
	}
	.goods_cell_price span::before {
		content: '$';
		font-size: 0.28rem;
	}
	
	.pay_price {
		font-size: 0.34rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #FF5000;
	}
	.disc_cell {
		width: 100%;
		display: flex;
		align-items: center;
		
	}
	.disc_btn {
		width: 1.6rem;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.24rem;
		margin-left: 0.24rem;
		color: #fff;
		background-color: #6681FA;
		border-radius: 0.08rem;
	}
	
	.group input {
		/* background: transparent; */
	}
	input:disabled {
		cursor: default;
		background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)) !important;
		color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
		border-color: rgba(118, 118, 118, 0.3);
	}
	.discing {
		padding-left: 0.12rem;
		margin-top: 0.12rem;
		color: rgba(12, 15, 30, 0.4);
	}
	.dis_input {
		border:0.01rem solid rgba(213,216,222,1);
		border-radius:0.08rem;
        margin-top: 0.24rem;
        height: 0.88rem;
	}
</style>